import {  Field, FieldType, ThresholdsMode, VizOrientation, getDisplayProcessor } from '@grafana/data';
import { BarGauge, BarGaugeDisplayMode } from '@grafana/ui';
import React from 'react';
interface YourBarGaugeComponentProps {
  progress: number;
  theme: any; // You should replace 'any' with the appropriate type for your theme
  max: number; // Replace 'number' with the correct type if needed
}

const YourBarGaugeComponent: React.FC<YourBarGaugeComponentProps> = ({ progress, theme,max }) => {
  const field: Partial<Field> = {
    type: FieldType.number,
    config: {
      min: 0,
      max: max,
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          { value: -Infinity, color: 'green' },
          { value: 40, color: 'red' },
          { value: 60, color: 'yellow' },
        ],
      },
    },
  };

  field.display = getDisplayProcessor({ field, theme });

  const barGaugeProps = {
    theme,
    lcdCellWidth: 12,
    itemSpacing: 8,
    showUnfilled: true,
    width: 150,
    height: 50,
    value: {
      text: progress.toString(),
      numeric: progress,
    },
    displayMode: BarGaugeDisplayMode.Basic, // Set it to a valid value
    orientation: VizOrientation.Horizontal,
    field: field.config,
    display: field.display,
  };

  return <BarGauge {...barGaugeProps} />;
};

export default YourBarGaugeComponent;
