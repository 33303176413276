import React, { useEffect, useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { MqttClient, connect } from "mqtt/dist/mqtt"

import { Button, ConfirmModal, Input, useTheme2 } from '@grafana/ui';
import CustomBarGaugeComponent from "./CustomBarGaugeComponent"
import { Paper, TableContainer, Table, TableHead, ThemeProvider, createTheme, TableCell, TableRow, TableBody } from '@mui/material';
interface Props extends PanelProps<SimpleOptions> {}


//<YourBarGaugeComponent progress={yourProgressValue} field={yourFieldData} />
//uwu




/*
const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};
*/
/*
interface TableTurnoff{
  id: number,
  groupname: string,
  settings: settings_turnoff ,
  done: boolean,
  created_at: number
}
*/
interface TableAmount{
  id: number;
  groupname: string;
  settings: SettingsAmount;
  done: boolean;
  created_at: number;
  progress: number;
  showModal: boolean;
}

/*
interface settings_turnoff{
command: string,
deviceList: deviceInfo[],
when: number
}
*/
/*
interface DeviceInfo{
  address: Number,
  name: string
}
*/
interface SettingsAmount{
  command: string,
  device: Device,
  when: number
}
interface Device{
  address: number,
  name: string,
  coef: number,
  turnoffLength: number
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const theme = useTheme2();
  //const styles = useStyles2(getStyles);
 
  const [tableData_amount,setTableData_amount] = useState<TableAmount[]>([]);
 
  /* 
  mqtt stuff
  */
  const [client,setClient] = useState<MqttClient>();
  const [update,setUpdate] = useState<Boolean>(false);
  //const [modal,setModal] = useState<boolean>(false);

  function generateRandomClientId(prefix: string, length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomPart = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    return `${prefix}${randomPart}`;
  }
  const mqttConnect = (host: string) => {
    const clientIdPrefix = 'client-';
  const clientIdLength = 10; // Adjust the length as needed
  const randomClientId = generateRandomClientId(clientIdPrefix, clientIdLength);
    //const brokerUrl = 'wss://46.36.40.68:6969';
    //setClient(connect(host));
  setClient(
    connect(host, {
  clientId: randomClientId,
  username: 'vemat',
  password: 'vemat1337',
  rejectUnauthorized: false
  }));
  }
  useEffect(()=>{
    //console.log("F");
      mqttConnect('mqtts://vemat.edima.io:6969');
 
      //mqttConnect('wss://46.36.40.68:6969');
  // eslint-disable-next-line
  },[]);
  useEffect(()=>{
    //console.log("Connected");
    if(client!== undefined){
      client.subscribe("vemat_quantity_progress",(err)=>{
        if(!err){
          //console.log("Subscribed to quantity progress");
        }
      })
    }
  },[client]);

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        //setConnectStatus('Connected');
        
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        //setConnectStatus('Reconnecting');
      });
      client.on('message', (topic, message) => {
        const payload = { topic, message: message.toString() };
        //console.log(payload);
        if(topic === "vemat_quantity_progress"){
        updateProgressAmount(payload);
        }
       
        //setPayload(payload);
      });
    }
  }, [client]);

  function updateProgressAmount(payload: any) {
    setTableData_amount(prevTableData => {
      // Create a copy of the existing data to avoid mutation
      const updatedTableData = [...prevTableData];
  
      if (updatedTableData && updatedTableData.length > 0) {
        const json = JSON.parse(payload.message);
        //console.log(json);
  
        // Find the item in the array and update its progress
        for (let i = 0; i < updatedTableData.length; i++) {
          if (updatedTableData[i].id === json.id) {
            updatedTableData[i].progress = json.length;
            break; // Exit the loop once the item is found
          }
        }
      }
  
      return updatedTableData;
    })};



  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource: {
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  
  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

  //table select for vemat_devices 
  //const sqlCommands = [`SELECT * FROM turnoff where done=false order by id asc`];
  //const rawSql = `SELECT address,name,coef FROM vemat_devices order by id desc`;

  //const query = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);

const headers = useMemo(() => {
  // Replace the following with your actual header initialization logic
  return {
    //Authorization: 'Bearer your_access_token',
    'Content-Type': 'application/json',
  };
}, []);
/*
const reloadTable = async () => {
 await fetchData(grafanaApiBaseUrl, method, headers, query).then(data =>
   {

    if (data) 
    {
      console.log("sj");
      console.log(data);
      if(data.results.A.frames.length>0){
        const frames = data.results.A.frames;
      
      frames.forEach((frame: any) => {
        const { data: { values } } = frame;
        const ids = values[0];
        const groupname = values[1];
        const settings = values[2];
        const done = values[3];
        const created_at = values[4];
   
        let amountoff_data: TableAmount[] = [];
        
        for (let i = 0; i < ids.length; i++) {
          const settingType = JSON.parse(settings[i]);
        
          switch(settingType.command){
          case "timedoff":
          //turn of based on time
        
          break;
            
            case "quantityoff":
          //turnoff based on quantity 
          amountoff_data.push({
            id: ids[i],
            groupname: groupname[i],
            settings: settingType,
            done: done[i],
            created_at: created_at[i],
            progress:0
          });

            break;

            case "solaroff":
              //turnoff based on solar energy
          
            break;

              default:
              break;
          
        }

       
     
        }
        
        setTableData_amount(amountoff_data);
        
      


      });
      
     
    }
    
      //napamovani nactenych dat do StateVariable
    }
    
  });

};
*/

useEffect(() => {
  //console.log("use effect reload table...");
  //reloadTable();
  
  
  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource: {
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  const method = 'POST';

  //table select for vemat_devices 
  const sqlCommands = [`SELECT * FROM turnoff where done=false order by id asc`];
  const query = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);

const headers =  {
    'Content-Type': 'application/json',
  };

  const reload = async ()=>{
    await fetchData(grafanaApiBaseUrl, method, headers, query).then(data =>
      {
   
       if (data) 
       {
         //console.log("data recieved :)");
         //console.log(data);
         if(data.results.A.frames.length>0){
           const frames = data.results.A.frames;
         
         frames.forEach((frame: any) => {
           const { data: { values } } = frame;
           const ids = values[0];
           const groupname = values[1];
           const settings = values[2];
           const done = values[3];
           const created_at = values[4];
      
           let amountoff_data: TableAmount[] = [];
           
           for (let i = 0; i < ids.length; i++) {
             const settingType = JSON.parse(settings[i]);
           
             switch(settingType.command){
             case "timedoff":
             //turn of based on time
           
             break;
               
               case "quantityoff":
             //turnoff based on quantity 
             amountoff_data.push({
               id: ids[i],
               groupname: groupname[i],
               settings: settingType,
               done: done[i],
               created_at: created_at[i],
               progress:0,
               showModal:false
             });
   //console.log("important data",amountoff_data);
               break;
   
               case "solaroff":
                 //turnoff based on solar energy
             
               break;
   
                 default:
                 break;
             
           }
   
          
        
           }
           
           setTableData_amount(amountoff_data);
           
         
   
   
         });
         
        
       }
       
         //napamovani nactenych dat do StateVariable
       }
       
     });
    
  };
  
  reload();
  return () => {
    // Cleanup code here
  };
}, [update,datasourceUID,grafanaApiBaseUrl]);


const handleDelete_amount = async (row: any,index: number,address: number) => {
   
  //setModal(false);
await doDelete(row.id,address);
const updated = [...tableData_amount];
updated.splice(index, 1);
setTableData_amount(updated);

};
const doDelete = async (id: number,address: number)=>{
  const deleteSql = `DELETE FROM turnoff WHERE id = ${id}`;
  const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
  await fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
  let payload = {"command":"turnOffCounterFlag","device":address};
  sendMqtt(payload);
}

function sendMqtt(payload: any){

if(client!==undefined){
  let jsonSend = payload;// {"command":"instantoff"};
  client.subscribe("VEMAT", (err) => {
    if (!err) {
      client.publish("VEMAT", JSON.stringify(jsonSend));
    }
  });
}
}


useEffect(() => {
  // Function to handle the event
  const handleEvent = (event: Event) => {
    //console.log("updateAmountOffList received" );
    sleep(100);
    setUpdate((prevUpdate) => !prevUpdate);
  };

  // Add the event listener when the component mounts
  document.addEventListener('updateAmountOffList', handleEvent);

  // Remove the event listener when the component unmounts
  return () => {
    document.removeEventListener('updateAmountOffList', handleEvent);
  };
   
}, []); // Empty dependency array ensures this effect runs only once

function sleep(ms: number | undefined) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


/*
  function convertUnixTimestampToCzechTime(unixTimestamp: number) {
    // Convert Unix timestamp to milliseconds
    const milliseconds = unixTimestamp * 1000;
  
    // Create a Date object from the milliseconds
    const date = new Date(milliseconds);
  
    // Set the options for formatting the date and time in Czech
    const _options = {
      year: 'numeric' as const,
      month: '2-digit' as const,
      day: '2-digit' as const,
      hour: '2-digit' as const,
      minute: '2-digit' as const,
      second: '2-digit' as const,
      timeZoneName: 'short' as const,
    }; 
    const czechDateTimeStr = date.toLocaleString('cs-CZ', _options);
  
    return czechDateTimeStr;

}
*/
/*
interface FlexContainerStyle {
  display: string;
  float: 'left' | 'right' | 'none'; // Specify the valid values for float
}

const flexContainerStyle: FlexContainerStyle = {
  display: 'flex',
  float: 'left' // Or 'right', or 'none' based on your requirement
};
*/
const table_theme = createTheme(
  {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        head: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        root: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
        },
      },
    },
  },
});

  return (
    
    
<ThemeProvider theme={table_theme}>



  <TableContainer 
    component={Paper} 
    style={{
          maxHeight: height, // Přidána dynamická výška podle parametru height
          overflowY: 'auto'  // Přidáno pro rolování obsahu
        }}
        elevation={0} // Nastaví elevation na 0, čímž odstraní stín
  >
    <Table>
      <TableHead>
        <TableRow>
          
          <TableCell>Zařízení</TableCell>
          <TableCell>Koeficient</TableCell>
          <TableCell>Požadovaná délka</TableCell>
          <TableCell>Progress bar</TableCell>
          <TableCell>Smazat</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

      {tableData_amount.map((row, index) => {
  
    return (
      <TableRow key={index}>


      <TableCell>
        <Input
          disabled={true}
          value={row.settings.device.name}
        />
      </TableCell>
      <TableCell>
      <Input
      disabled={true}
        value={row.settings.device.coef}
/>
     

      </TableCell>
      <TableCell>
      <Input
      disabled={true}
        value={row.settings.device.turnoffLength}
/>
     

      </TableCell>
         <TableCell>

         <CustomBarGaugeComponent progress={row.progress} theme={theme} max={row.settings.device.turnoffLength} />

      </TableCell>

            <TableCell>
            <Button size="md" variant='destructive' onClick={()=>setTableData_amount(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: true } : device))} >Smazat</Button>
            
            <ConfirmModal isOpen={row.showModal}
            title={"Smazat množestvní zastavení"}
             body={"Opravdu chcete smazat množstevní zastavení?"}
              description={"Zařízení bude smazáno z množstevní tabulky"} 
              confirmText={"Smazat"} confirmButtonVariant={"primary"}
               dismissText={"Zrušit"} icon={"exclamation-triangle"}
                onConfirm={()=>handleDelete_amount(row,index,row.settings.device.address)} onDismiss={()=>setTableData_amount(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: false } : device))} />
               
            </TableCell>

          </TableRow>
    );
                })}
    

  
      </TableBody>
    </Table>
   
    <div>
  <div>
</div>


</div>
  </TableContainer>
 
  </ThemeProvider>
  );
};






